import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/homePage";
import Destinationdetails from "./pages/destinationdetails";
import FolkSafar from "./pages/folkSafar";
import React, { useEffect } from "react";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/destination/:id" element={<Destinationdetails />} />
          <Route path="/festival/:id" element={<Destinationdetails />} />
          <Route path="/folksafar" element={<FolkSafar />} />

          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
