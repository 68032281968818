// import { useState, useLocation } from "react";
import { Link } from "react-router-dom";
import CoverPic from "../assets/folksafar_top.jpg";
import Pic1 from "../assets/group1.png";
import Pic2 from "../assets/group2.png";
import Pic3 from "../assets/group3.png";
import "./folkSafar.css";
import HomeImg from "../assets/home1.png";

const FolkSafar = () => {
  return (
    <div className="FolksafarCover">
      <Link to="/">
        <div className="stickyHome">
          <img src={HomeImg} alt="" />
        </div>
      </Link>
      <div className="FolkCover">
        <img alt=" " className="FolkImg" src={CoverPic} />
      </div>
      <div className="writingwrapper">
        <p className="fs2 coverWrite">
          Folk Safar is an initiative of promoting Cultural Tourism in rural
          West Bengal, supported by the Department of Tourism, Government of
          West Bengal. The 3 days festival at Mohar Kunj from Feb 18-20, 2022
          will showcase folk song, dance and theatre and handicraft of West
          Bengal, involving more than 200 rural traditional ICH bearers. An
          exhibition on cultural tourism will help willing visitors to plan
          their next trip to experience art and craft villages of West Bengal.
          We welcome all to experience the cultural milieu of artists in
          promoting rural cultural tourism of West Bengal.
        </p>
        <div className="Inaugurationtitle">
          <p className="fs1 actualtext">Inauguration on February 18 at 6 pm</p>
        </div>
        <div className="fs3 imagewrapper">
          <div className="CardsWrapper">
            <div className="cards">
              <div className="imgandtext">
                <img alt=" " className="circularImg" src={Pic1} />
                <div className="fs1 textwithcircle">
                  Evening Concert [Ground I]
                </div>
              </div>
              <div className="textwrapper">
                <p style={{ marginBottom: "40px" }}>6:30 pm - 8 pm</p>
                <p>Day 1:</p>
                <p style={{ marginBottom: "30px" }}>Bauls of Bengal</p>
                <p>Day 2:</p>
                <p style={{ marginBottom: "30px" }}>Folks of Bengal</p>
                <p>Day 3:</p>
                <p>Folk Theatre -</p>
                <p>Bhanusundarir Pala</p>
              </div>
            </div>

            <div className="cards">
              <div className="imgandtext">
                <img alt=" " className="circularImg" src={Pic2} />
                <div className="fs1 textwithcircle">Day Stage [Ground II]</div>
              </div>
              <div className="textwrapper">
                <p style={{ marginBottom: "40px" }}>2.30 pm  - 6 pm</p>
                <p>Day 1:</p>
                <p style={{ marginBottom: "30px" }}>
                  Pater Gaan, Baul, Sundarban Jhumur, Chau
                </p>
                <p>Day 2:</p>
                <p style={{ marginBottom: "30px" }}>
                  Putul Naach, Baul, Bhawaiya, Chau
                </p>
                <p>Day 3:</p>
                <p>Jhumur Gaan, Bhawaiya, Raibenshe</p>
              </div>
            </div>
          </div>
          <div className="secondcard">
            <div className="cards special">
              <div className="imgandtext" style={{ width: "fit-content", margin: "auto" }}>
                <img alt=" " className="circularImg" src={Pic3} />
                <div className="fs1 textwithcircle">
                  Handicraft Display [Ground III]
                </div>
              </div>
              <div className="extratextwrapper">
                <p style={{ marginBottom: "40px", textAlign: "center" }}>11 am – 6 pm</p>
                <div className="threegrid">
                  <div style={{ textAlign: "right", marginRight: "5px" }}>
                    <p>Chau Mask: Purulia</p>
                    <p>Clay Doll: Nadia</p>
                    <p>Dhokra: Uttar Dinajpur</p>
                    <p>Wooden Mask: Dakshin Dinajpur</p>
                    <p>Bamboo Mask: Dakshin Dinajpur</p>
                    <p>Kantha: Birbhum, Purba Bardhaman</p>
                    <p>Handloom Kenjakura: Bankura</p>
                    <p>Patachitra: Paschim and Purba Medinipur</p>
                    <p>Basketry: Malda, Uttar Dinajpur, Purba Bardhaman</p>
                  </div>
                  <div>
                  </div>
                  <div style={{ textAlign: "left", marginLeft: "5px"}}>
                    <p>Pottery: Uttar Dinajpur</p>
                    <p>Sabai: Jhargram and Purulia</p>
                    <p>Shola: South 24 Pgs, Birbhum</p>
                    <p>Sitalpati: Coochbehar</p>
                    <p>Terracotta: Bankura</p>
                    <p>Tribal Patachitra: Purulia</p>
                    <p>Wooden Doll: Purba Bardhaman</p>
                    <p>Dokra: Bankura, Purba Bardhaman</p>
                    <p>Madur: Paschim and Purba Medinipur</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* kjnsd */}
        </div>
      </div>
    </div>
  );
};
export default FolkSafar;
