import { useParams, Link } from "react-router-dom";

import { useState, useEffect } from "react";
import HomeImg from "../assets/home1.png";
import CallImg from "../assets/call1.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./destinationdetails.css";
import LightBox, { Modal, ModalGateway } from "react-images";

const data = [
  {
    id: "Patacharita",
    coverImg: "cover_pat.png",
    name: "Patachitra Village",
    tag: "Experience the natural colour making and strokes of the scroll painters",
    date: "Feb 25-27, 2022",
    perform: "Cultural Performance: Pater Gaan, Baul Fakiri Music and Chau",
    video: "https://www.youtube.com/embed/3_jsSWbNogY",
    trail:
      "<p>Naya village in Pingla is home to 255 Patachitra painters or 'Patuas'. Patuas paint stories in a series of frames on long scrolls of cloth using natural colours and sing the stories as they unfurl the scrolls. Nowadays they also make a range of diverse products like apparels, stationery items and home decor using Patachitra motifs. Patachitra has received the Geographical Indication (GI) tag.</p><p>The village has a Folk Art Centre and Community Museum developed by the Department of MSME&T, Government of West Bengal, where one can learn about different kinds of scrolls, the stories and marvel at the wide range of diversified products like t-shirts, hand fans, lamps, bags, umbrellas etc. One can attend workshops on Patachitra painting, learn to make natural colours out of leaves and flowers.</p><p>This year Patachitra mela at Naya, Pingla in Paschim Medinipur will be held on 25-27 February with the support of the Department of Tourism, Government of West Bengal. We invite you to Pingla Patachitra mela 2022.</p><p>If you are visiting Naya, you may also visit the nearby mat weavers hub at Sarta, Sabang.</p><p>Trail: Patachitra village, Madur village at Sabang and heritage places around Jhargram.</p>",
    stay: "<p> One can stay at any place of his/ her choice, however here are few options:</p><p>Hazra Guest House - 9433239496</p> <p>Zulfikar Guest House - 9438072926 in Pingla.</p><p>Pingla Folk Art Centre - 9932851321.</p>",
    contact:
      "<p>1.Mantu Chitrakar – 9733521335</p><p>2.Amit Chitrakar -7602006594</p><p>3.Swarna Chitrakar - 9732799107</p><p>4.Rahim Chitrakar - 9932851321</p>",

    img: [
      "POTMaya/1.JPG",
      "POTMaya/2.JPG",
      "POTMaya/3.jpg",
      "POTMaya/4.JPG",
      "POTMaya/5.jpg",
      "POTMaya/6.jpg",
      "POTMaya/7.jpg",
      "POTMaya/8.JPG",
      "POTMaya/9.jpg",
      "POTMaya/10.JPG",
      "POTMaya/11.jpg",
    ],
  },

  {
    id: "Charida",
    coverImg: "cover_chaumask.png",
    name: "Chau mask makers village at Charida",
    tag: "Experience the tradition and learn about the different Chau masks used in the performance.",
    date: "Feb 25-27, 2022",
    perform: "Cultural Performance: Chau performances all three days",
    video: "https://www.youtube.com/embed/7340AmqazH4",
    trail:
      "<p>Charida, is a village of more than 300 skilled mask makers of the celebrated dance form of the region, Chau dance. It is an acrobatic martial art based dance form inscribed in UNESCO Representative List of Intangible Cultural Heritage of Humanity. This quaint village in the land of red soil is located at the scenic foothills of the Ajodhya Hills, about 5 kms from Baghmundi.The tradition of Chau mask making started in Charida about 150 years back during the rule of King Madan Mohan Singh Deo of Baghmundi. The Chau Mask has received the Geographical Indication (GI) tag. The vibrant, colourful and elaborate masks convey the vigorous intent of the art form that portrays animals or characters from the epics. One can experience the process of making a mask directly from the artists. The hub has a Folk Art Centre and Community Museum developed by the Department of MSME&T, Government of West Bengal.</p><p>This year Chau mask mela at Charida in Purulia will be held on 25-27 February with the support of the Department of Tourism, Government of West Bengal. We invite you to Charida mela 2022.</p><p>Trail: Charida - Chau mask village, Ajodhya hills, Chau village at Balarampur, Deulghata at Joypur. On the way back one can visit Garhpanchakot, Telkupi, Baran, Kashipur palace and the heritage around Bankura.</p>",
    stay: "<p> One can stay at any place of his/ her choice, however here are few options:</p><p>Malgudi resort (8945500559)</p> <p>Banjara Camp (9641858953)</p> <p>Murugama Dam – Palash Bitan (9674222670)</p> <p>Bon Palashi (9874361951)</p> <p>Hotels at Ajodhya, Aangina at Charida (home stay run by a local NGO, 9732057183)</p> <p>Folk centre at Balarampur (9064480856), Baranti, Jaychandi and Garhpanchakot.</p>",
    contact:
      "<p>1.Bhim Sutradhar – 9635304740</p><p>2.Falguni Sutradhar - 9735129308</p><p>3.Dharmendra Sutradhar - 9673719388</p>",
    img: [
      "ChauMaskMela/1.jpg",
      "ChauMaskMela/2.jpg",
      "ChauMaskMela/3.jpg",
      "ChauMaskMela/4.JPG",
      "ChauMaskMela/5.jpg",
      "ChauMaskMela/6.JPG",
      "ChauMaskMela/7.jpg",
      "ChauMaskMela/8.JPG",
      "ChauMaskMela/9.jpg",
    ],
  },

  {
    id: "Dokra",
    coverImg: "cover_dokra.png",
    name: "Dokra village",
    tag: "Experience Dokra, the lost wax metal casting technique at Bikna",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Jhumur, Chau, Baul",
    video: "https://www.youtube.com/embed/jB6-wQ23xu0",
    trail:
      "<p>Bikna village is close to Bankura town. It is home to approximately 150 artists practicing the primitive Dokra art. Dokra is the art of melting figurines through the extinct metal casting technique. Dokra is characterized by primitive simplicity, rustic beauty, imaginative designs and patterns making it a coveted collector's item. Dokra has received the Geographical Indication (GI) tag. The hub has a Folk Art Centre and Community Museum developed by the Department of MSME&T, Government of West Bengal.</p><p>One can plan a day trip to Bikna for a sneak peek into the lives of the artists and experience the craft making process. One can also visit Bishnupur, Mukutmanipur, Susunia from Bikna.</p><p>This year on 4-6 March, Bikna will hold its annual mela and is supported by Department of Tourism, Government of West Bengal. We invite you all to join Bikna Dokra mela 2022.</p><p>Trail: Dokra Hub, Heritage tourism around Bishnupur including Terracotta village at Panchmura, Susunia and Mukutmanipur.</p>",
    stay: "<p>One can stay at any place of his/ her choice in Bankura, however if anyone combines Bishnupur and Bikna, here are few options: <p>Bishnupur Tourist Lodge (9732100950)</p> <p>Banalata Resort (9732111706)</p> <p>Other hotels around Bishnupur, Pachmura folk centre maintained by artists cluster (9732187161)</p> <p>Bikna Folk Art Centre (9932546842).</p>",
    contact:
      "<p>1.Harendranath Rana - 9932601095</p><p>2.Somnath Karmakar - 9932546842</p>",
    img: [
      "DokraMela/1.JPG",
      "DokraMela/2.jpg",
      "DokraMela/3.JPG",
      "DokraMela/4.JPG",
      "DokraMela/5.jpg",
      "DokraMela/6.JPG",
      "DokraMela/7.JPG",
      "DokraMela/8.jpg",
      "DokraMela/9.jpg",
      "DokraMela/10.jpg",
      "DokraMela/11.jpg",
    ],
  },

  {
    id: "Panchmura",
    coverImg: "cover_terracotta.png",
    name: "Terracotta village at Panchmura",
    tag: "Experience the iconic Terracotta village at Panchmura.",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Chau and Folks of Bengal performance",
    video: "https://www.youtube.com/embed/twp9TbQTwK8",
    trail:
      "<p>Terracotta of Bankura is one of the first attempts of man at clay modeling that has been perfected with me. Baked in sun and burnt in mud kilns with dry eucalyptus leaves, the earthly ensembles in dull ochre or red are beauteous caricatures. Terracotta has received the Geographical Indication (GI) tag.</p><p>The simple yet dynamic terracotta artistry of Panchmura has its origin in religious ritual. The structure of the 'Bankura Horse' has been so fashioned to symbolize a mark of devotion and valour. Panchmura offers a wide range of Terracotta products from animal to human figurines as well as utility, home décor and jewellery. There are around 270 Terracotta artists in Panchmura. One can participate in craft -making workshops, learn the history of the craft from the artists. Panchmura folk centre is getting developed by the Department of MSME&T, Government of West Bengal. Panchmura is 23 km from Bishnupur.</p><p>This year on 4-6 March, Panchmura will hold its annual mela and is supported by Department of Tourism, Government of West Bengal. We invite you all to join Panchmura Terracotta mela 2022.</p><p>Trail: Heritage tourism around Bishnupur including Panchmura Terracotta village and Bikna Dokra hub in Bankura.</p>",
    stay: "<p>One can stay at any place of his/ her choice in Bishnupur, however here are few options:</p> <p>Bishnupur Tourist Lodge (9732100950)</p> <p>Banalata Resort (9732111706), other hotels around Bishnupur</p> <p>Pachmura folk centre maintained by artists cluster (9732187161). </p>",
    contact:
      "<p>1.Bhutnath Kumbhakar - 9732187161</p><p>2.Jagannath Kumbhakar – 8101737907</p>",
    img: [
      "TerracottaMela/1.jpg",
      "TerracottaMela/2.jpg",
      "TerracottaMela/3.jpg",
      "TerracottaMela/4.jpg",
      "TerracottaMela/5.jpg",
      "TerracottaMela/6.JPG",
      "TerracottaMela/7.jpg",
      "TerracottaMela/8.jpg",
      "TerracottaMela/9.jpg",
      "TerracottaMela/10.jpg",
      "TerracottaMela/11.jpg",
    ],
  },

  {
    id: "GorbhangaJalangi",
    coverImg: "cover_gorbhanga.png",
    name: "Gorbhanga and Jalangi",
    tag: "Soulful Music of the Fakirs",
    date: "Feb 25-27, 2022",
    perform: "Cultural Performance: Fakiri performance",
    video: "https://www.youtube.com/embed/l6OyT9ouqmA",
    trail:
      "<p>Gorbhanga village of Nadia and Jalangi of Murshidabad district is the home of the Fakirs, the makers of soulful, mellifluous music. The villages symbolize a quiet celebration of peace and free flowing music - the elementary essence of the village, practiced in the age-old Baul tradition. There are community folk art centres in Gorbhanga and Jalangi to preserve the tradition of Baul-Fakiri songs. Gorbhanga is also a seat of learning where Bauls from other parts of Nadia and Murshidabad come to learn and exchange. Gorbhanga is home of well known fakir/ musicians like Arman Fakir, Golam Fakir, Babu Fakir, Amirul Fakir, Khaibar Fakir, Noor Alam. The village that has evolved as a popular destination amongst music enthusiasts is an ideal one if one is seeking soul searching music in the natural habitat of the music genre. One can visit Gorbhanga round the year to enjoy the music, jam with the musicians, and experience the lifestyle and philosophy of the Fakirs. Jalangi is only 45 minutes from Gorbhanga and a folk centre is getting developed by the Department of MSME&T, Government of West Bengal.</p><p>Trail: Music tourism trail around Gorbhanga in Nadia, Jalangi in Murshidabad and other places like Goas, Chapra, Asannagar in Nadia, Hariharpara in Murshidabad</p>",
    stay: "<p>One can stay at any place of his/ her choice at Krishnanagar/ Baharampur, however here are few options: </p> <p>Artists houses at Gorbhanga (9733948841)</p> <p>Artist’s houses at Jalangi (9732917198).</p>",
    contact:
      "<p>1.Babu Fakir, Gorbhanga – 9733948841</p><p>2.Arman Fakir, Gorbhanga –  9733956858</p><p>3.Khaibar Fakir, Gorbhanga - 9647190624</p><p>4.Chote Golam, Jalangi - 9732917198</p>",
    img: [
      "GorbhangaJalangi/1.jpg",
      "GorbhangaJalangi/2.JPG",
      "GorbhangaJalangi/3.jpg",
      "GorbhangaJalangi/5.jpg",
      "GorbhangaJalangi/7.jpg",
      "GorbhangaJalangi/8.jpg",
      "GorbhangaJalangi/9.jpg",
      "GorbhangaJalangi/10.jpg",
      "GorbhangaJalangi/11.JPG",
    ],
  },

  {
    id: "Natungram",
    coverImg: "cover_natungram.png",
    name: "Wooden Doll Village at Natungram",
    tag: "Experience Wooden Doll making and Heritage Tourism",
    date: "Feb 25-27, 2022",
    perform: "Cultural Performance: Raibenshe and Baul",
    video: "https://www.youtube.com/embed/9WyNs9ONZXs",
    trail:
      "<p>Natungram is the hub of wooden doll makers also known as 'Sutradhars' (narrator or storyteller). Carved out of a single piece of wood, these dolls from ancient folklore and mythology are characterised by their vibrant colours and distinctive ethnic style. The owl, considered auspicious as the escort of the goddess of prosperity, Lakshmi, made in Natungram, enjoys an iconic status among Bengal's handicrafts.</p><p>A Folk Art Centre and Community Museum has been developed for the artists by the Department of MSME&T, Government of West Bengal. with lodging facilities equipped with all basic amenities. Visitors at Natungram can witness the artists at work, explore the Folk Art Centre to learn about the craft and its technique and learn the stories behind it.</p><p>Trail: Natungram, Heritage sites around Kalna including Nabadwip, Katwa and Santiniketan can also be visited.</p>",
    stay: "<p>One can stay at any place of his/ her choice around Bardhaman/ Kalna/ Kawa/ Santiniketan, or at village Folk Art Centre (7679099205).</p>",
    contact:
      "<p>1.Bijoy Sutradhar - 7872214736</p><p>2.Dilip Sutradhar - 9333386501</p>",
    img: [
      "Natungram/1.JPG",
      "Natungram/2.JPG",
      "Natungram/3.JPG",
      "Natungram/4.JPG",
      "Natungram/5.JPG",
      "Natungram/6.JPG",
      "Natungram/7.JPG",
      "Natungram/8.JPG",
      "Natungram/9.jpg",
    ],
  },

  {
    id: "Balrampur",
    coverImg: "cover_maldi.png",
    name: "Chau village at Maldi, Balarampur",
    tag: "Experience Chau - the masked dance of Purulia",
    date: "Feb 25-27, 2022",
    perform: "Cultural Performance: Chau performances",
    video: "https://www.youtube.com/embed/RcfnduuvbVs",
    trail:
      "<p>Purulia is a pied panorama of nature, people, culture and heritage. It is the land of the spectacular Chau dance inscribed in the Representative List of Intangible Cultural Heritage of Humanity by UNESCO in 2010.</p><p>Chau is a colorful, martial mask dance of Eastern India. The performances, called Palas, begin with Jhumur song followed by Shehnai. The dance is marked by acrobatic twists and turns. The stories are based on mythological tales propagating moral and ethical values. Nearly 6,000 artists pursue this vibrant performing folk art tradition in Purulia. A Folk Art Centre at Maldi village in Balarampur is getting developed by the Department of MSME&T, Government of West Bengal.</p><p>Trail: Chau dance villages around Balarampur, Bandwan & Burrabazar, Chau mask maker’s village at Charida, natural heritage around Ayodhya Hills, Deulghata.</p>",
    stay: "<p>One can stay at any place of his/ her choice, however here are few options:</p> <p>Malgudi resort (8945500559)</p> <p>Banjara Camp (9641858953)</p> <p>Murugama Dam – Palash Bitan (9674222670)</p> <p>Bon Palashi (9874361951)</p> <p>Hotels at Ajodhya, Aangina at Charida (home stay run by a local NGO, 9732057183)</p> <p>Folk centre at Balarampur (9064480856), Baranti, Jaychandi and Garhpanchakot.</p>",
    contact:
      "<p>1.Jagannath Chowdhury - 9064480856</p><p>2.Shyamali Chowdhury - 9382143764</p>",
    img: [
      "Maldi/1.jpg",
      "Maldi/2.jpg",
      "Maldi/3.jpg",
      "Maldi/4.JPG",
      "Maldi/5.JPG",
    ],
  },

  {
    id: "Sundarban",
    coverImg: "cover_sundarban.png",
    name: "Sundarban",
    tag: "Experience nature and cultural tourism around Satjhelia, Dayapur, Pakhiralay",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Bhatiyali, Banbibir Pala and Jhumur Dance.",
    video: "https://www.youtube.com/embed/qhi27qbQJV8",
    trail:
      "<p>Sundarban is a UNESCO World Heritage site and is renowned for its bio-diversity, unique landscape and the iconic mangrove forests. This landscape of the Sundarban inspires the folk traditions of this land, such as Banbibir Pala, a mythological play that revolves around the age-old conflict of man and animal. The mystic rivers of the Sundarbans inspire soul-stirring Bhatiyali songs. Likewise, another local tradition, Jhumur dance and songs, resonates through time.</p><p>The land offers visitors an experience of a lifetime. The tourists can also visit artists’ villages like Pakhiralay, Satjhelia, Dayapur in Gosaba block and Jogeshganj in Hingalganj block and experience folk forms of the delta region. There are more than 800 artists practicing the tradition of Bhatiyali, Jhumur and Banbibir Pala. There is a folk centre getting developed by the Department of MSME&T, Government of West Bengal.</p><p>Trail: Different islands of Sundarban.</p>",
    stay: "<p>Hotels at Pakhiralay & home stay available at Bali and Jharkhali.</p> <p>Jharkhali – Aranyak Home Stay (7865857711)</p> <p>Sarkar Home stay (9775621037)</p> <p>Mangrove Wild Home Stay (9748831356)</p> <p>Akash Home Stay (9733538463)</p> <p>Bali – Sunderban JD Camp (9775517654)</p> <p>Sajnekhali : Matla TP – (9836776010)</p> <p>Mainak/ Badaban.Hotels at Pakhiralay & home stay available at Bali and Jharkhali. Jharkhali – Aranyak Home Stay (7865857711)</p> <p>Sarkar Home stay (9775621037)</p> <p>Mangrove Wild Home Stay (9748831356)</p> <p>Akash Home Stay (9733538463)</p> <p>Bali – Sunderban JD Camp (9775517654)</p> <p>Sajnekhali : Matla TP – (9836776010), Mainak/ Badaban.</p>",
    contact:
      "<p>1.Rabindra Mondal / Putul Mondal - 8001953147 (Satjelia - Banbibir Pala)</p><p>2.Baburam Sardar - 9153854927 (Pakhiralay - Banbibir Pala)</p><p>3.Ramapada Mistry - 9564495574 (Kalitala - Banbibir Pala)</p><p>4.Dipali Sarkar - 9002730858 (Jogeshganj - Banbibir Pala)</p><p>5.Mitali Mondal - 8967632767 (Lahiripur - Jhumur)</p><p>6.Bablu Sardar - 8967670226 (Satjelia - Jhumur)</p><p>7.Bishnupada Sarkar - 8159845715 (Kalitala - Baithiyali)</p><p>8.Manoranjan Koyal - 9800970287 (Kalitala - Baithiyali)</p><p>9.Rafikul Islam Gaji - 9775211605 (Sahebkhali - Baithiyali)</p><p>10.Artists cluster coordinator - Puranjan Mondal 9732820530</p>",
    img: [
      "Sundarban/1.JPG",
      "Sundarban/2.jpg",
      "Sundarban/3.JPG",
      "Sundarban/4.JPG",
      "Sundarban/5.JPG",
      "Sundarban/6.jpg",
      "Sundarban/7.jpg",
      "Sundarban/8.jpg",
    ],
  },

  {
    id: "Santiniketan",
    coverImg: "cover_santiniketan.png",
    name: "Santiniketan",
    tag: "Cultural experience around Santiniketan.",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Raibenshe and Baul",
    video: "https://www.youtube.com/embed/8d37H8nsxEM",
    trail:
      "<p>Birbhum is the “The land of red soil (Lalbhumi)” also known as “the land of the brave” or Virbhumi. Remnants of civilizations add profound historical importance to this place. Several kings have dominated Birbhum since the beginning. Birbhum is noted for its topography and its cultural heritage which is unique and is somewhat different from that of the other districts in West Bengal. There is a great prominence of Bauls, martial art of Raibenshe, Shola craft and Kantha embroidery. Some of the prominent Baul akhras around Bolpur are that of Rina Das Baul and Rabi Das Baul</p><p>Visitors interested to travel to Joydev can visit Baul Akhras. Raibenshe, the prominent traditional martial art dance form that combines acrobatic movements and balancing tricks is also prominent in Charkhol, near Nanoor. Nanoor of Birbhum is known for the intrinsic Kantha work. Kantha is unique in its ethnicity, traditional appeal and contemporary allure..</p><p>Trail: Santiniketan, Nanoor Kantha hub, Baul akhras in and around Santiniketan, Shola at Surul, Raibenshe village Charkol.</p>",
    stay: "<p>Rangabitan and Santobitan Tourist Lodge : 9732100920</p> <p>Guloncho Home Stay : 9831056023</p> <p>Nayatara Home Stay : 9836814406/9831210861</p> <p>Nibhriti Guest House 9823616967/9158900000</p> <p>Tepantar Theatre village : 9434646982 (located at other side of Ajoy, opposite Joydev Kenduli)</p> <p>Udashi Babar Akhra at Ballabhpur (9830028621).</p>",
    contact:
      "<p>1.Rina Das (Paruldanga - Baul) - 9800120227</p><p>2.Rabi Das (Purbapalli - Baul) - 9614196848</p><p>3.Sadhu Das (Joydev - Baul) – 9732892009</p><p>4.Lakshman Das Baul (Joydev – Baul) - 8900441430</p><p>5.Rakshankar Pramanik (Charkhol - Raibenshe) - 8016474415</p><p>6.Mongol Bittar (Ahmedpur - Raibenshe) - 9609711252</p><p>7.Sunil Thandar (Charkhol - Raibenshe) - 9609620069</p><p>8.Tajkira Begum (Kantha of Nanoor) - 9474413141</p><p>9.Lovely Bibi (Kantha of Nanoor) - 8372994373</p><p>10.Afroza Khatun (Kantha of Nanoor) - 6294807939</p><p>11.Lutfa Sultana (Kantha of Nanoor) - 8389928571</p><p>12.Kamal Malakar (Shola of Surul) - 9474009311</p><p>13.Kanchan Malakar (Shola of Surul) - 9563200730</p>",
    img: [
      "Shantiniketan/1.JPG",
      "Shantiniketan/2.JPG",
      "Shantiniketan/3.jpg",
      "Shantiniketan/4.jpg",
      "Shantiniketan/5.JPG",
      "Shantiniketan/6.JPG",
      "Shantiniketan/7.JPG",
      "Shantiniketan/8.jpg",
      "Shantiniketan/9.JPG",
      "Shantiniketan/10.JPG",
    ],
  },

  {
    id: "Dariyapur",
    coverImg: "cover_dariyapur.png",
    name: "Dariyapur",
    tag: "Home to a primeval craft – Dokra",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Baul, Jhumur, Chadar Badar (tribal puppetry)",
    video: "https://www.youtube.com/embed/nyCmq9EVGjA",
    trail:
      "<p>Dariyapur, a village in Bardhaman district, is popularly known for a unique craft, Dokra. One of the earliest methods of non-ferrous metal casting, Bengal Dokra has received the Geographical Indication (GI) tag. Artisans of the Dariyapur village settled here about 120 years back from which Dokra has evolved from a household craft to one which is revered all over the world for its simple yet rustic appeal.</p><p>Witness live demonstrations of Dokra craft making, know about intricate designs and folk motifs and meet the practitioners in engaging workshops. Artists make a wide range of items starting from utility to decor. The Folk Art Centre and Community Museum has been developed by the Department of MSME&T, Government of West Bengal. Other than an elaborate display of the crafts and its making at the Dariyapur festival ground, you may also visit their folk art museum and witness the rare collection of antique Dokra artifacts.</p><p>Trail: Dokra Hub, Ancient temples like Sat Deul and 108 Shiva Temple, Bhalki Machan.</p>",
    stay: "<p>One can stay at any place of his/ her choice at Guskara/ Bardhaman, however here are few options:</p> <p>Bannabagram Baul Ashram (9674295394)</p> <p>Tepantar Theatre Village (9434646982)</p> <p>Bhalki Machan (9434537545).</p>",
    contact:
      "<p>1.Subho Karmakar -  9153255955</p><p>2.Suresh Karmakar  - 7872287512</p><p>3.Subhas Chandra Mandal – 9735228086</p><p>4.Gouranga Karmakar – 8001058766</p><p>5.Sanjay Karmakar – 9775369912</p><p>6.6.Ashok Karmakar - 9635673082</p>",
    img: [
      "Dariyapur/1.jpg",
      "Dariyapur/2.jpg",
      "Dariyapur/3.jpg",
      "Dariyapur/4.jpg",
      "Dariyapur/5.jpg",
      "Dariyapur/6.jpg",
      "Dariyapur/7.JPG",
      "Dariyapur/8.JPG",
    ],
  },

  {
    id: "Ausgram",
    coverImg: "cover_ausgram.png",
    name: "Ausgram : Nature and Culture",
    tag: "Enliving Kantha and Baul traditions",
    date: "Mar 4-6, 2022",
    perform: "Cultural Performance: Baul, Chadar Badar (tribal puppetry)",
    video: "https://www.youtube.com/embed/qqLB3AtNM8A",
    trail:
      "<p>Ausgram located in Purba Bardhaman is the hub of Kantha artists. There are more than 1000 artists living in around Ausgram. Bannabagram in Ausgram located centrally has a Baul ashram for practice and promotion of Baul music. There are also Jhumur and Chadar Badar (tribal puppetry) artists in the region.</p><p>Trail: Dokra Hub, Ancient temples like Sat Deul and 108 Shiva Temple, Bhalki Machan.</p>",
    stay: "<p>One can stay at any place of his/ her choice at Guskara/ Bardhaman, however here are few options:</p> <p>Bannabagram Baul Ashram (9674295394)</p> <p>Tepantar Theatre Village (9434646982)</p> <p>Bhalki Machan (9434537545).</p>",
    contact:
      "<p>1.Jagannath Chandra : 9932861168</p><p>2.Saiful Molla : 9674295394</p>",
    img: [
      "Ausgram/1.JPG",
      "Ausgram/2.jpg",
      "Ausgram/3.jpg",
      "Ausgram/4.jpg",
      "Ausgram/5.jpg",
      "Ausgram/6.jpg",
      "Ausgram/7.JPG",
    ],
  },
];

const DestinationDetails = () => {
  const { id } = useParams();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 4,
    },

    smallDesktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 3.2,
    },

    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [index, selectedIndex] = useState(0);
  const [lightboxopen, setlightbox] = useState(false);
  const [imgdata, setimgdata] = useState([]);

  const toggleLightbox = (selectedindex) => {
    setlightbox(!lightboxopen);
    selectedIndex(selectedindex);
    console.log(index);
  };
  const showImgdata = () => {
    console.log(imgdata);
  };
  const [aStateVariable, setAStateVariable] = useState(false);

  const handleClick = () => {
    setAStateVariable(true);
  };

  useEffect(() => {
    if (aStateVariable === true) {
      window.scrollTo(0, 0);
    }
  }, [aStateVariable]);

  // state = {
  //   awsApiData: [],
  //   // loading: false,
  //   lightboxIsOpen: false,
  //   // selectedIndex: 0,
  //   selectedImage: {},
  // };

  // data.map((items) => items.id === id(setimgdata(items.img)));

  return data.map((items) =>
    items.id === id ? (
      <div className="destinationWrapper" onLoad={handleClick}>
        <Link to="/">
          <div className="stickyHome">
            <img src={HomeImg} alt="" />
          </div>
        </Link>
        <div className="DestinationCover">
          <img
            alt=" "
            className="DestinationImg"
            src={require(`../assets/coverpics/${items.coverImg}`)}
          />
        </div>
        <div className="secondhalfcontainer">
          <div className="videoandtext">
            <div className="videoContainer">
              <iframe
                className="video"
                title="video"
                src={items.video}
              ></iframe>
            </div>
            <div className="textwithvideo">
              <div className="textwrap">
                <div className="fs4 textName">{items.name}</div>
                <div className="fs2 data1">{items.tag}</div>
                <div className="fs2 data2">{items.date}</div>
                <div className="fs2 data3">{items.perform}</div>
              </div>
            </div>
          </div>

          <div className="justText">
            <div></div>
            <div
              dangerouslySetInnerHTML={{
                __html: items.trail,
              }}
            ></div>
          </div>

          <div className="carouselWrapper">
            <Carousel
              responsive={responsive}
              infinite={true}
              swipeable={true}
              draggable={true}
              autoPlaySpeed={1000}
              transitionDuration={500}
            >
              {items.img.map((element, indx) => (
                <div
                  className="carouselimgcontain"
                  style={{ cursor: "pointer" }}
                  key={indx}
                  // onClick={() => {
                  //   toggleLightbox(indx);
                  //   setimgdata(items.img.map);
                  //   showImgdata();
                  // }}
                >
                  <img
                    alt=" "
                    src={require(`../assets/${element}`)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}
            </Carousel>
            <ModalGateway>
              {lightboxopen ? (
                <Modal onClose={toggleLightbox}>
                  <LightBox
                    components={{
                      FooterCaption: () => <div>footer caption</div>,
                    }}
                    currentIndex={index}
                    views={imgdata}
                    // formatters={{ getAltText }}
                    // frameProps={{ autoSize: "height" }}
                    // views={items.img.map((element) => (
                    //   <img
                    //     alt=" "
                    //     src={require(`../assets/${element}`)}
                    //     style={{ cursor: "pointer" }}
                    //   />
                    // ))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>

          <div className="contactDetails">
            <div className="contactdetailwrapper">
              <div className="firstdetail">
                <div className="circleandtext">
                  <div className="circulardiv">
                    <img alt=" " src={HomeImg} />
                  </div>
                  <div>
                    <div className="justTextnew">Stay At</div>
                  </div>
                </div>
                <div className="detailsforcircle">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: items.stay,
                    }}
                  ></div>
                </div>
              </div>

              <div className="firstdetail">
                <div className="circleandtext">
                  <div className="circulardiv">
                    <img alt=" " src={CallImg} />
                  </div>
                  <div>
                    <div className="justTextnew">Artist Contact</div>
                  </div>
                </div>
                <div className="detailsforcircle">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: items.contact,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
};
export default DestinationDetails;
