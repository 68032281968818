import { Link, useNavigate } from "react-router-dom";

import CoverPic from "../assets/top_panel_folksf.png";
import Logo1 from "../assets/bengalt_logo.png";
import Logo2 from "../assets/bnc_logo.png";
import "./homePage.css";
import PhoneI from "../assets/phone_icon.png";
import MailI from "../assets/mail_icon.png";
import DownloadBtn from "../assets/download_btn.png";
import { saveAs } from "file-saver";

const Home = () => {
  let navigate = useNavigate();

  // const redirectTo = () => {
  //   navigate.push("https://v5.reactrouter.com/web/api/Hooks/usehistory");
  // };
  const saveFile = () => {
    const saveSync = require("../assets/ct_brochureA5.pdf");
    saveAs(saveSync, "ct_brochure.pdf");
  };

  const data = [
    {
      type: "festival",
      Link: "/festival/Patacharita",
      img: "square01.png",
    },
    {
      type: "festival",
      Link: "/festival/Charida",
      img: "square02.png",
    },
    {
      type: "festival",
      Link: "/festival/Dokra",
      img: "square03.png",
    },
    {
      type: "festival",
      Link: "/festival/Panchmura",
      img: "square04.png",
    },
    {
      type: "destination1",
      Link: "/destination/GorbhangaJalangi",
      img: "square05.png",
    },
    {
      type: "destination1",
      Link: "/destination/Natungram",
      img: "square06.png",
    },
    {
      type: "destination1",
      Link: "/destination/Balrampur",
      img: "square07.png",
    },
    {
      type: "destination1",
      Link: "/destination/Sundarban",
      img: "square08.png",
    },
    {
      type: "destination2",
      Link: "/destination/Santiniketan",
      img: "square09.png",
    },
    {
      type: "destination2",
      Link: "/destination/Dariyapur",
      img: "square10.png",
    },
    {
      type: "destination2",
      Link: "/destination/Ausgram",
      img: "square11.png",
    },
  ];

  return (
    <div className="homePagewrapper">
      <div className="logoContainer">
        <img alt=" " className="logo1" src={Logo1} />
        <img alt=" " className="logo2" src={Logo2} />
      </div>
      <div className="fs4 heading1">
        Department of Tourism in association with banglanatak dot com
      </div>
      <div className="fs4 heading">Cultural Tourism of West Bengal</div>
      <Link to="/folksafar">
        <div className="HomeCover">
          <img alt=" " className="HomeImg" src={CoverPic} />
        </div>
      </Link>
      <div className="villagefestivalConatain">
        <div className="fs4 villageTitle">Village Festivals</div>
        <div className="villagefestcardcontain">
          {data.map((items) =>
            items.type == "festival" ? (
              <Link to={items.Link}>
                <div className="cardContainer">
                  <img
                    alt=" "
                    src={require(`../assets/squarePics/${items.img}`)}
                  />
                </div>
              </Link>
            ) : null
          )}
        </div>
      </div>

      <div className="destinationfestivalConatain">
        <div className="fs4 destinationTitle">Weekend Destinations</div>
        <div className="destinationfestcardcontain">
          {data.map((items) =>
            items.type == "destination1" ? (
              <Link to={items.Link}>
                <div className="cardContainer">
                  <img
                    alt=" "
                    src={require(`../assets/squarePics/${items.img}`)}
                  />
                </div>
              </Link>
            ) : null
          )}

          <div className="weekendCardcontainer2">
            {data.map((items) =>
              items.type == "destination2" ? (
                <Link to={items.Link}>
                  <div className="cardContainer">
                    <img
                      alt=" "
                      src={require(`../assets/squarePics/${items.img}`)}
                    />
                  </div>
                </Link>
              ) : null
            )}
          </div>
        </div>
      </div>
      <div className="footerwrap">
        <div className="footer">
          <button className="downloadBtn" onClick={saveFile}>
            <img src={DownloadBtn} alt="" />
          </button>
          <div className="phonewrap">
            <img src={PhoneI} alt="" className="phoneBtn" />
            <p>8420106396</p>
          </div>
          <div className="mailwrap">
            <img src={MailI} alt="" className="mailBtn" />
            <p>ct.wbtourism@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
